.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
/* .gmnoprint div {
    background:none !important;
} */
/* .gm-fullscreen-control {
  background:none !important;
} */

.gm-style iframe + div { border:none!important; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.button_blue{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #63BDFF;
  font-size: 12px;
  padding: 2px 16px 2px 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 999px;
  background-color: #00202A;
  user-select: none;
}
.button_blue:hover{
  background-color: #10303A;
}
.button_white{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #fff;
  font-size: 12px;
  padding: 2px 16px 2px 16px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 999px;
  background-color: #FFFFFF26;
}
.button_white:hover{
  background-color: #FFFFFF46;
}
.table_header{
  font: bold;
  font-size: 10px;
  color: white;
  padding: 16px 16px 16px 16px;
  text-wrap: pretty;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.table_item{
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.subpage_selected{
  padding: 8px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid white;
  font-size: 14px;
}
.subpage_not_selected{
  padding: 8px;
  color: #525252;
  cursor: pointer;
  border-bottom: 1px solid #525252;
  font-size: 14px;
}
iframe { display:block; }




.d-flex-m-hidden{
  display: flex;
}
.d-row-m-col{
  flex-direction: row;
}
.d-flex-m-hidden{
  display: flex;
}
.d-hidden-m-flex{
  display: none;
}
.d-row-m-column{
  flex-direction: row;
}
.d-w-50-m-100{
  width: 50%;
}
@media only screen and (max-width: 1024px) {
  .d-row-m-col{
    flex-direction: column;
  }
  .d-flex-m-hidden{
    display: none;
  }
  .d-hidden-m-flex{
    display: flex;
  }
  .d-row-m-column{
    flex-direction: column;
  }
  .d-w-50-m-100{
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .d-flex-m-hidden{
    display: none;
  }
  .main-window-lock{
    min-width: 1920px;
  }
}
@media only screen and (max-width: 888px) {
  .main-window-lock{
    min-width: 1920px;
  }
}